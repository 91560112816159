@import url("https://fonts.googleapis.com/css2?family=Dongle&family=Oxygen:wght@300;400;700&display=swap");

* {
  font-family: "Oxygen", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.active {
  background-color: #dddddd;
}

._loading_overlay_wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
}

._loading_overlay_overlay {
  height: 100vh;
  width: 100vw;
  z-index: 900000 !important;
  position: fixed;
  background-color: rgba(0,0,0,0.4) !important;
}

.MuiCollapse-root {
  z-index: 999000 !important;
  position: relative;
}

span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
  color: rgba(0, 0, 0, 0.54);
}